










































































































































































































































































































































































































































































































































































































.q-linear-progress
  height: 1rem
  margin: 0 0 0.5rem

.inline-list
  padding: 0 1rem

  li
    margin-right: 3rem

.bar
  .bar-progress
    height: 40px
    .bar-progress-total
      background-color: #e9ecef
      font-size: .75rem
      height: 100%
      overflow: hidden
      width: 100%
      position: relative

      .bar-progress-value
        position: absolute
        left: 0
        bottom: 0
        height: 100%

        &.bar-progress-value--inverted
          right: 0
          left: unset
