.q-linear-progress {
  height: 1rem;
  margin: 0 0 0.5rem;
}
.inline-list {
  padding: 0 1rem;
}
.inline-list li {
  margin-right: 3rem;
}
.bar .bar-progress {
  height: 40px;
}
.bar .bar-progress .bar-progress-total {
  background-color: #e9ecef;
  font-size: 0.75rem;
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: relative;
}
.bar .bar-progress .bar-progress-total .bar-progress-value {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
}
.bar .bar-progress .bar-progress-total .bar-progress-value.bar-progress-value--inverted {
  right: 0;
  left: unset;
}
/*# sourceMappingURL=src/pages/entities/EntityInformations.css.map */